import { element } from 'prop-types';
import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
(
    <Suspense fallback={<SuspenseLoader />}>
        <Component {...props} />
    </Suspense>
);

const PDFview = Loader(lazy(() => import('src/content/applications/PDFViewer')));

const pdfRoutes = [
    {
        path: '/',
        element: <Navigate to='invoice' />
    },
    {
        path: 'invoice',
        children : [
            {
                path:':pdfPath',
                element: <PDFview/>
            }
        ]
    },
    {
        path: 'sale-order',
        children : [
            {
                path:':pdfPath',
                element: <PDFview/>
            }
        ]
    },
]

export default pdfRoutes
