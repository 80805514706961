import type { ReactNode } from 'react';

import WidgetsIcon from '@mui/icons-material/Widgets'

export interface MenuItem {
  link?: string;
  icon?: ReactNode;
  badge?: string;
  badgeTooltip?: string;

  items?: MenuItem[];
  name: string;
}

export interface MenuItems {
  items: MenuItem[];
  heading: string;
}


const menuItems: MenuItems[] = [
  {
    heading: '',
    items: [
      {
        name: 'Panel',
        icon: WidgetsIcon,
        link: '/top-navigation/control',
        items: []
      },
    ]
  },
];

/* {
            name: 'Compras',
            link: 'dashboards/sales'
          }, */

/**
 * 
 * /* {
    heading: 'Management',
    items: [
      {
        name: 'User Profile',
        icon: AssignmentIndTwoToneIcon,
        link: '/top-navigation/management/users/single',
      },
      {
        name: 'Invoices',
        icon: ReceiptTwoToneIcon,
        link: '/top-navigation/management/invoices',
       
      }
    ]
  }, */


export default menuItems;
