import { PartialRouteObject } from 'react-router';

import Authenticated from 'src/components/Authenticated';
import { Navigate } from 'react-router-dom';

import BaseLayout from 'src/layouts/BaseLayout';
import AccentHeaderLayout from 'src/layouts/AccentHeaderLayout';
import AccentSidebarLayout from 'src/layouts/AccentSidebarLayout';


import dashboardsRoutes from './dashboards';
import applicationsRoutes from './applications';
import managementRoutes from './management';
import accountRoutes from './account';
import baseRoutes from './base';
import pdfRoutes from './pdf';

const router: PartialRouteObject[] = [
  {
    path: 'account',
    children: accountRoutes
  },
  {
    path: '*',
    element: <BaseLayout />,
    children: baseRoutes
  },

  //Accent Sidebar Layout

  {
    path: 'accent-sidebar',
    element: (
      <Authenticated>
        <AccentSidebarLayout />
      </Authenticated>
    ),
    children: [
      {
        path: '/',
        element: <Navigate to="dashboards" replace />
      },
      {
        path: 'dashboards',
        children: dashboardsRoutes
      }, 
      {
        path: 'management',
        children: managementRoutes
      },
      {
        path: 'pdf-view',
        children: pdfRoutes
      }
    ]
  },

  // Accent Header Layout

   {
    path: 'accent-header',
    element: (
      <Authenticated>
        <AccentHeaderLayout />
      </Authenticated>
    ),
    children: [
      {
        path: '/',
        element: <Navigate to="dashboards" replace />
      },
      {
        path: 'dashboards',
        children: dashboardsRoutes
      },
      {
        path: 'applications',
        children: applicationsRoutes
      },
      {
        path: 'management',
        children: managementRoutes
      }
    ]
  }, 
];

export default router;
