import {
  Box,
  Card,
  Typography,
  Container,
  styled
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import useAuth from 'src/hooks/useAuth';
import OdooLogin from '../LoginOdoo';
import { useTranslation } from 'react-i18next';
import Logo from 'src/components/LogoSign';
import { useState } from 'react';

const MainContent = styled(Box)(
  () => `
    height: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
`
);

const TopWrapper = styled(Box)(
  () => `
  display: flex;
  width: 100%;
  flex: 1;
  padding: 20px;
`
);

function LoginBasic() {
  const { method } = useAuth() as any;
  const { t }: { t: any } = useTranslation();
  const [userName, setUserName] = useState("")

  return (
    <>
      <Helmet>
        <title>Login - Servicios Cloud Advance</title>
      </Helmet>
      <MainContent>
        <TopWrapper>
          <Container maxWidth="sm">

            <Card
              sx={{
                mt: 3,
                px: 4,
                pt: 5,
                pb: 3,
              }}
            >
              <Box>
                <Logo rootPath="/account/login" />
                <Typography
                  variant="h4"
                  sx={{
                    marginTop:"1.5em",
                    marginBottom:".5em",
                    justifyContent:"center",
                    textAlign:"center",
                    fontWeight:500,
                  }}
                >
                  {userName ? `Hola ${userName}`: "Inicia sesión"}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    marginTop:".5em",
                    marginBottom:"1em",
                    justifyContent:"center",
                    textAlign:"center",
                    fontSize:".85em"
                  }}
                >
                  Acceder a Portal Cloud 
                </Typography>
              </Box>

              <OdooLogin setUserName={setUserName} />
            </Card>
          </Container>
        </TopWrapper>
      </MainContent>
    </>
  );
}

export default LoginBasic;
