import {
  Box,
  Tooltip,
  Badge,
  TooltipProps,
  tooltipClasses,
  styled,
  useTheme
} from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Logo_advance = 'https://res.cloudinary.com/ddors0hsv/image/upload/v1698244739/recursos%20web%20advance/eeannrmauqhdvdkky8ui.png';

const LogoWrapper = styled(Link)(
  ({ theme }) => `
        color: ${theme.palette.text.primary};
        display: flex;
        text-decoration: none;
        margin: 0 auto;
        z-index:1000;
        width:auto;
        height:auto;
        justify-content:center;
        font-weight: ${theme.typography.fontWeightBold};`
        
);

const TooltipWrapper = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.colors.alpha.trueWhite[100],
    color: theme.palette.getContrastText(theme.colors.alpha.trueWhite[100]),
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 'bold',
    borderRadius: theme.general.borderRadiusSm,
    boxShadow:
      '0 .2rem .8rem rgba(7,9,25,.18), 0 .08rem .15rem rgba(7,9,25,.15)'
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.colors.alpha.trueWhite[100]
  }
}));

function Logo(props) {
  const rootPath = props.rootPath || "/account/login"
  const imgSize = props.size || "50%"
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();

  return (
    <TooltipWrapper title={t('Advance Servicios Integrales')} arrow>
      <>
        <LogoWrapper to={rootPath}>
          <img style={{ minWidth: "100px", maxWidth: imgSize }}
            alt="advance"
            src={Logo_advance} />
        </LogoWrapper>
      </>
    </TooltipWrapper>
  );
}

export default Logo;
