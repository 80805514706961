import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Dashboards

const Servers = Loader(lazy(() => import('src/content/dashboards/Servers')));
const Helpdesk = Loader(lazy(() => import('src/content/dashboards/Helpdesk')));
const Products = Loader(lazy(() => import('src/content/dashboards/Products')));
const Invoices = Loader(lazy(() => import('src/content/dashboards/Invoices')));

const dashboardsRoutes = [
  {
    path: '/reports',
    element: <Servers />
  },
  {
    path: '/',
    element: <Navigate to="control" replace />
  },
  {
    path: 'control',
    element: <Servers />
  },
  {
    path: 'products',
    element: <Products />
  },
  {
    path: 'helpdesk',
    element: <Helpdesk/>
  },
  {
    path: 'invoices',
    element: <Invoices/>
  },
  {
    path: 'sales',
    element: <Navigate to="/status/coming-soon" replace />
  }
];

export default dashboardsRoutes;
