import axios from 'axios';

axios.defaults.baseURL = `${process.env.REACT_APP_NODE_PATH}`
const axiosInt = axios.create();

axiosInt.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || 'There is an error!' + error
    )
);

export default axiosInt;

