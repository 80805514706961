import * as Yup from 'yup';
import { FC, useEffect, useRef, useState } from 'react';
import { Formik } from 'formik';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';

import {
  Box,
  Button,
  FormHelperText,
  TextField,
  InputAdornment,
  Grid,
  Typography,
  CircularProgress,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import useAuth from 'src/hooks/useAuth';
import useRefMounted from 'src/hooks/useRefMounted';
import { useTranslation } from 'react-i18next';

import Swal from 'sweetalert2'
import axiosInt from 'src/utils/axios';

const LoginOdoo = ({ setUserName }) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { login } = useAuth() as any;
  const isMountedRef = useRefMounted();
  const { t }: { t: any } = useTranslation();
  const [isUserEmail, setUserEmail] = useState(false)
  const [changePassField, setModePassField] = useState(false)

  const verifyUserEmail = (value) => {
    axiosInt.post(`${process.env.REACT_APP_NODE_PATH}/advance/verify-email`, {
      email: value
    })
      .then(data => {
        if (data.status == 200) {
          setUserEmail(true)
          setUserName(data?.data?.name)
        } else {
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: 'Tu correo no se encuentra registrado',
            showConfirmButton: false,
            timer: 1500
          })
        }
      })
      .catch(error => {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: 'Error al Conectar',
          text: 'verifica el correo y vuelve a intentar',
          showConfirmButton: false,
          timer: 2000
        })
      })
  }

  const SweetAlert = (type, title, message) => {
    Swal.fire({
      icon: type,
      title: title,
      text: message
    })
  }

  return (
    <Formik
      initialValues={{
        email: searchParams.get('email') || "",
        password: '',
        terms: true,
        submit: null
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email(t('El email provisto deberia ser una dirección valida'))
          .max(255),

        password: Yup.string()
          .max(255),

        terms: Yup.boolean().oneOf(
          [true],
          t('Debes aceptar terminos y condiciones del servicio')
        )
      })}
      onSubmit={async (
        values,
        { setErrors, setStatus, setSubmitting }
      ): Promise<void> => {
        try {
          await login(values.email, values.password);

          if (isMountedRef.current) {
            setStatus({ success: true });
            setSubmitting(false);
          }
        } catch (err) {
          SweetAlert("error", "Error en el inicio de Sesión", "comprueba credenciales")
          if (isMountedRef.current) {
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }): JSX.Element => (
        <form noValidate style={{ overflowX: "hidden", overflowY: "visible" }} onSubmit={handleSubmit}>

          <Grid
            id="formGrid"
            container
            flexWrap="nowrap"
            width="200%"
            position="relative"
            sx={{
              transform: `${isUserEmail && "translateX(-50%)"}`,
              transition: ".5s"
            }}
          >
            <Grid item xs={12} p={1}>
              <TextField
                error={Boolean(touched.email && errors.email)}
                disabled={isUserEmail}
                fullWidth
                margin="normal"
                autoFocus
                helperText={touched.email && errors.email}
                label={t('Dirección de Correo')}
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                type="email"
                value={values.email}
                variant="outlined"
                onKeyDown={(e) => {
                  if (e.key === "Enter") verifyUserEmail(values.email)
                }}
              />
              {/* <Link color="primary" component={RouterLink} to="/account/recover-password">
                <b>{t('¿Has olvidado tu correo?')}</b>
              </Link> */}

              <Button
                sx={{
                  mt: 3
                }}
                style={{
                  color: "#fff"
                }}
                color='primary'
                startIcon={isSubmitting ? <CircularProgress size="1rem" /> : null}
                disabled={isSubmitting}
                fullWidth
                onClick={() => { verifyUserEmail(values.email) }}

                size="large"
                variant="contained"
              >
                {t('Siguiente')}
              </Button>
            </Grid>
            <Grid item xs={12} p={1}>
              <TextField
                error={Boolean(touched.password && errors.password)}
                fullWidth
                margin="normal"
                helperText={touched.password && errors.password}
                label={t('Contraseña')}
                name="password"
                onBlur={handleBlur}
                onChange={handleChange}
                type={changePassField ? "text" : "password"}
                value={values.password}
                variant="outlined"
                color='primary'
                InputProps={{
                  endAdornment: (
                    <InputAdornment onClick={() => { setModePassField(prev => !prev) }} position='end' style={{ cursor: "pointer" }}>
                      {changePassField ? <VisibilityOff /> : <Visibility />}
                    </InputAdornment>
                  )
                }}
              />

              <Box
                alignItems="center"
                display="flex"
                justifyContent="space-between"
              >
                <>
                  <Typography
                    style={{
                      color: "#0cc",
                      cursor: "pointer"
                    }}
                    variant="body2"
                    onClick={() => {
                      setUserEmail(false)
                    }}
                  >
                    {t('Cambiar correo')}
                  </Typography>
                </>

                {/* <Link style={{ color: "#0cc" }} component={RouterLink} to="/account/recover-password">
                  <b>{t('olvidaste tu contraseña?')}</b>
                </Link> */}
              </Box>

              {Boolean(touched.terms && errors.terms) && (
                <FormHelperText error>{errors.terms}</FormHelperText>
              )}

              {isUserEmail &&
                <Button
                  sx={{
                    mt: 3
                  }}
                  style={{
                    color: isSubmitting ? "#0cc" : "#fff",
                    backgroundColor: isSubmitting ? "transparent" : "#0cc",
                    border: isSubmitting ? "1px solid #0cc" : "",
                    transition: "3s easy"
                  }}
                  startIcon={isSubmitting ? <CircularProgress size="1rem" /> : null}
                  disabled={isSubmitting}
                  type="submit"
                  fullWidth
                  size="large"
                >
                  {isSubmitting ? "Cargando" : "Iniciar Sesión"}
                </Button>
              }
            </Grid>
          </Grid>

        </form>
      )}
    </Formik>
  );
};

export default LoginOdoo;
